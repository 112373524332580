<template>
    <!-- 机库列表 -->
    <div class="hangar-list">
        <div class="hangar-list_top">
            <div class="search-top">
                <select-tree v-model="searchForm.deptId" :data="projectTeamOptions" size="mini" :clearable="true" 
                    :placeholder="$t('mission.team')" @select-change="gethHangarData" @clear="gethHangarData" />
                <el-input class="hangar_input" v-model="searchForm.hangarName" :placeholder="$t('hangar.hangarName')" 
                    size="mini" suffix-icon="el-icon-search" clearable @blur="gethHangarData" />
            </div>
            <el-input v-model="searchForm.droneName" :placeholder="$t('hangar.droneName')" 
                size="mini" suffix-icon="el-icon-search" clearable @blur="gethHangarData" />
            <el-date-picker type="datetimerange" v-model="searchForm.time" range-separator="~" size="mini" 
                :start-placeholder="$t('startTime')" :end-placeholder="$t('endTime')" :default-time="['00:00:00','23:59:59']" 
                value-format="yyyy-MM-dd HH:mm:ss" clearable @change="gethHangarData" />
        </div>
        <div class="hangar-list_center">
            <div class="no-data" v-if="!hangarData || !hangarData.length">
                <i class="el-icon-folder-delete" />
                <p>暂无数据...</p>
            </div>
            <div class="list_item" :class="{'active': item.id === chooseHangarItem.id}" 
                v-for="(item, index) in hangarData" :key="item.id + index" @click="chooseHangarData(item, 'select')">
                <div class="list_item-top">
                    <el-tooltip class="item" effect="dark" :content="item.name || '--'" placement="top-start">
                        <div class="list_item-title">{{item.name || '--'}}</div>
                    </el-tooltip>
                    <div class="list_item-icon">
                        <i class="el-icon-aim" v-show="item.id === chooseHangarItem.id" 
                            @click.stop="locationItem(item)"></i>
                        <i class="el-icon-edit-outline" v-show="item.id === chooseHangarItem.id" 
                            @click.stop="editItem(item)"></i>
                        <i class="el-icon-delete" v-show="item.id === chooseHangarItem.id" 
                            @click.stop="deleteItem(item)"></i>
                        <i class="el-icon-view" :class="{'active': item.visibility}" 
                            @click.stop="showHideEntity(item)"></i>
                    </div>
                </div>
                <el-tooltip class="item" effect="dark" :content="item.deptNames || '--'" placement="right">
                    <div class="list_item-msg">{{item.deptNames || '--'}}</div>
                </el-tooltip>
                <div class="list_item-msg">{{item.equipmentName || '--'}}</div>
            </div>
        </div>
        <div class="hangar-list_bottom">
            <el-button type="primary" icon="el-icon-plus" class="bottom_btn" @click="addHangar">
                {{$t('hangar.addHangar')}}</el-button>
        </div>
        <!-- 新增编辑机库弹窗 -->
        <el-dialog class="hangarBaseDialog" :title="hangarBasePopConfig.isAdd ? $t('hangar.addHangar') : $t('hangar.editHangar')" 
            :visible.sync="hangarBasePopConfig.isShow" width="800px" :modal-append-to-body="false" :close-on-click-modal="false" 
            :close-on-press-escape="false" top="15vh"  :before-close="closeHangarBasePop">
            <div class="content">
                <el-form :model="hangarBaseForm" :rules="hangarBaserRules" ref="hangarBaseForm" label-width="auto" 
                    class="demo-ruleForm">
                    <div class="form-item">
                        <el-form-item :label="$t('hangar.equipmentNumber') + '：'" prop="serialNumber">
                            <el-input v-model="hangarBaseForm.serialNumber" size="small" style="width: 100%;" 
                                :placeholder="$t('hangar.equipmentNumber')" />
                        </el-form-item>
                        <el-form-item :label="$t('airportManager.deviceName') + '：'" prop="name">
                            <el-input v-model="hangarBaseForm.name" size="small" style="width: 100%;" 
                            :placeholder="$t('airportManager.deviceName')" />
                        </el-form-item>
                    </div>
                    <div class="form-item">
                        <el-form-item :label="$t('hangar.equipmentBrand') + '：'" prop="manufacturerId">
                            <el-select v-model="hangarBaseForm.manufacturerId" size="small" style="width: 100%;" 
                                :placeholder="$t('hangar.equipmentBrand')" @change="changeEquipmentBrand">
                                <el-option v-for="item in equipmentBrandData" :key="item.id" :label="item.name" 
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>
                        <el-form-item :label="$t('hangar.equipmentModel') + '：'" prop="equipmentNumber">
                            <el-select v-model="hangarBaseForm.equipmentNumber" size="small" style="width: 100%;" 
                                :placeholder="$t('hangar.equipmentModel')">
                                <el-option v-for="item in equipmentModelData" :key="item.id" :label="item.dictKey" 
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="form-item">
                        <el-form-item :label="$t('hangar.bindingDrones') + '：'">
                            <el-select v-model="hangarBaseForm.equipmenId" size="small" style="width: 100%;" 
                                filterable :placeholder="$t('hangar.bindingDrones')">
                                <el-option v-for="item in bindingDronesData" :key="item.id" :label="item.equipmentName" 
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>
                        <el-form-item :label="$t('mission.team') + '：'" prop="deptIdList">
                            <select-tree v-model="hangarBaseForm.deptIdList" :multiple="true" :data="projectTeamOptions" 
                                size="small" style="width: 100%;" :placeholder="$t('mission.team')" />
                        </el-form-item>
                    </div>
                    <div class="form-item">
                        <el-form-item :label="$t('remark') + '：'">
                            <el-input v-model="hangarBaseForm.remark" type="textarea" :rows="4" style="width: 100%;" 
                                :placeholder="$t('remark')" />
                        </el-form-item>
                        <el-form-item :label="$t('hangar.deviceImages') + '：'">
                            <div class="upload_cont">
                                <div class="img_cont" v-if="hangarBaseForm.pics">
                                    <el-image style="width: 100%; height: 100%" :src="hangarBaseForm.pics" 
                                        :preview-src-list="[hangarBaseForm.pics]" fit="contain" />
                                    <div class="delete-icon"></div>
                                    <i class="el-icon-delete" @click="deletePics"></i>
                                </div>
                                <div class="upload" v-else @click="chooseFiles">
                                    <i class="el-icon-plus"></i>
                                </div>
                                <input style="display:none" type="file" ref="hangar-upload" 
                                    accept="image/*" @change="handleFiles($event)" />
                            </div>
                            <div class="remark">{{$t('hangar.deviceImagesRemark')}}</div>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="closeHangarBasePop">{{$t('cancelText')}}</el-button>
                <el-button size="small" type="primary" :loading="hangarBasePopConfig.loading" 
                    @click="submitHangarData('hangarBaseForm')">{{$t('submitText')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {getProjectTeamOptions} from "@/api/project/index";
import {getBrandList} from "@/api/uav/brandManagement";
import {getEquipmentList} from '@/api/airportManager/index';
import {getEquipmentTypeList, uploadFile, getHangarPage, changeHangarVisibility, 
    getHangarDetail, deleteHangarItem, addEditHangar} from "@/api/hangar/index";

export default {
    name: 'hangarList',
    data() {
        return {
            equipmentBrandData: [],
            equipmentModelData: [],
            bindingDronesData: [],
            projectTeamOptions: [],
            searchForm: {
                deptId: '',
                hangarName: '',
                droneName: '',
                time: [],
            },
            hangarData: [],
            chooseHangarItem: {},
            hangarBasePopConfig: {
                isShow: false,
                isAdd: true,
                loading: false
            },
            hangarBaserRules: {
                serialNumber: [
                    {required: true, message: this.$t('hangar.equipmentNumber'), trigger: 'blur'}
                ],
                name: [
                    {required: true, message: this.$t('airportManager.deviceName'), trigger: 'blur'}
                ],
                manufacturerId: [
                    {required: true, message: this.$t('hangar.equipmentBrand'), trigger: 'change'}
                ],
                equipmentNumber: [
                    {required: true, message: this.$t('hangar.equipmentModel'), trigger: 'change'}
                ],
                deptIdList: [
                    {required: true, message: this.$t('mission.team'), trigger: 'blur'}
                ]
            },
            hangarBaseForm: {
                serialNumber: '',
                name: '',
                manufacturerId: '',
                equipmentNumber: '',
                equipmenId: '',
                deptIdList: '',
                remark: '',
                pics: ''
            }
        }
    },
    created() {
        this.getBrandData();
        this.getProjectTeamOptions();
        this.gethHangarData();
        this.searchForm = {
            deptId: '',
            hangarName: '',
            droneName: '',
            time: [],
        };
        this.chooseHangarItem = {};
    },
    mounted() {
        this.$EventBus.$off('choose-hangar-data');
        this.$EventBus.$on('choose-hangar-data', data => {
            this.chooseHangarData(data, 'noToFly');
        });
    },
    methods: {
        // 获取设备品牌
        getBrandData() {
            getBrandList().then((res) => {
                if (res.data.code == 200) {
                    this.equipmentBrandData = res.data.data.records || [];
                }
            });
        },
        // 获取设备型号
        getEquipmentTypeData() {
            let item = this.equipmentBrandData.find(t => t.id === this.hangarBaseForm.manufacturerId);
            let params = {
                parentKey: item.code || '',
                childValue: 'hangar-number'
            }
            getEquipmentTypeList(params).then(res => {
                if (res.data.code == 200) {
                    this.equipmentModelData = res.data.data || [];
                }
            });
        },
        // 获取无人机列表
        getUavEquipmentData() {
            let params = {
                selectType: 'uav',
                current: 1,
                size: 9999,
                manufacturerId: this.hangarBaseForm.manufacturerId
            };
            getEquipmentList(params).then(res => {
                if (res.data.code == 200) {
                    this.bindingDronesData = res.data.data ? (res.data.data.records || []) : [];
                }
            });
        },
        // 获取团队集合
        getProjectTeamOptions() {
            getProjectTeamOptions().then((res) => {
                if (res.data.code === 200) {
                    this.projectTeamOptions = this.filterListData(res.data.data || [], 'title', 'value');
                }
            });
        },
        filterListData(arr, labelKey, valueKey, disabledParent) {
            let res = [];
            for (var i = 0; i < arr.length; i++) {
                let item = arr[i];
                res.push({
                    label: item[labelKey],
                    value: item[valueKey],
                    disabled: disabledParent && item.children ? true : false,
                    children: (item.children && Array.isArray(item.children) && item.children.length > 0) 
                        ? this.filterListData(item.children, labelKey, valueKey): undefined
                });
            }
            return res;
        },
        // 获取机库列表
        gethHangarData() {
            let params = {
                deptIds: this.searchForm.deptId,
                name: this.searchForm.hangarName,
                equipmentName: this.searchForm.droneName,
                timeStart: '',
                timeEnd: ''
            }
            if (this.searchForm.time && this.searchForm.time.length >= 2) {
                params.timeStart = this.searchForm.time[0];
                params.timeEnd = this.searchForm.time[1];
            }
            getHangarPage(params).then(res => {
                if (res.data.code == 200) {
                    this.hangarData = res.data.data.records || [];
                    this.$EventBus.$emit('draw-hangar-data', this.hangarData);
                }
            });
        },
        // 选择机库列表数据
        chooseHangarData(data, type) {
            if (this.chooseHangarItem.id === data.id) return;
            this.chooseHangarItem = data;
            if (type !== 'noToFly') this.locationItem(data);
        },
        // 定位实体
        locationItem(data) {
            this.$EventBus.$emit('hangar-location', data);
        },
        // 编辑
        editItem(data) {
            getHangarDetail({id: data.id}).then(res => {
                if (res.data.code == 200) {
                    this.hangarBaseForm = res.data.data;
                    this.hangarBaseForm.manufacturerId && this.getEquipmentTypeData();
                    this.hangarBaseForm.manufacturerId && this.getUavEquipmentData();
                    this.hangarBasePopConfig = {
                        isShow: true,
                        isAdd: false,
                        loading: false
                    };
                }
            });
        },
        // 删除
        deleteItem(data) {
            this.$confirm('此操作将永久删除不可恢复, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteHangarItem({ids: data.id}).then(res => {
                    if (res.data.code == 200) {
                        this.$message({type: 'success', message: '删除成功!'});
                        let item = this.hangarData.find(t => t.id === data.id);
                        if (item) {
                            let index = this.hangarData.indexOf(item);
                            if (index !== -1) {
                                this.hangarData.splice(index, 1);
                            }
                        }
                        this.$EventBus.$emit('delete-hangar-success', data);
                    }
                });
            });
        },
        // 显示隐藏实体
        showHideEntity(data) {
            let params = {
                id: data.id,
                visibility: data.visibility ? 0 : 1
            }
            changeHangarVisibility(params).then(res => {
                if (res.data.code === 200) {
                    data.visibility = params.visibility;
                    this.$EventBus.$emit('change-hangar-show', params.id, params.visibility);
                }
            });
        },
        // 新增机库
        addHangar() {
            this.hangarBaseForm = {
                serialNumber: '',
                name: '',
                manufacturerId: '',
                equipmentNumber: '',
                equipmenId: '',
                deptIdList: '',
                remark: '',
                pics: ''
            };
            this.hangarBasePopConfig = {
                isShow: true,
                isAdd: true,
                loading: false
            };
        },
        // 关闭机库新增编辑弹窗
        closeHangarBasePop() {
            this.hangarBasePopConfig = {
                isShow: false,
                isAdd: true,
                loading: false
            };
            this.$refs.hangarBaseForm.resetFields();
            this.hangarBaseForm = {
                serialNumber: '',
                name: '',
                manufacturerId: '',
                equipmentNumber: '',
                equipmenId: '',
                deptIdList: '',
                remark: '',
                pics: ''
            };
            this.equipmentModelData = [];
        },
        // 设备品牌改变时
        changeEquipmentBrand() {
            this.hangarBaseForm.equipmentNumber = '';
            if (!this.hangarBaseForm.manufacturerId) {
                this.equipmentModelData = [];
                return;
            }
            this.getEquipmentTypeData();
            this.getUavEquipmentData();
        },
        // 选择文件
        chooseFiles() {
            this.$refs['hangar-upload'].value = '';
            this.$refs['hangar-upload'].click();
        },
        // 文件改变时
        handleFiles(e) {
            let formData = new FormData();
            formData.append('file', e.target.files[0]);
            uploadFile(formData).then(res => {
                if (res.data.code === 200) {
                    this.hangarBaseForm.pics = res.data.data.link;
                }
            });
        },
        // 删除机库图片
        deletePics() {
            this.hangarBaseForm.pics = '';
        },
        // 提交保存机库信息
        submitHangarData(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let params = JSON.parse(JSON.stringify(this.hangarBaseForm));
                    params.deptIds = JSON.stringify(params.deptIdList)
                    this.hangarBasePopConfig.loading = true;
                    addEditHangar(params).then(res => {
                        if (res.data.code === 200) {
                            this.$message({type: 'success', message: '保存成功!'});
                            this.closeHangarBasePop();
                            this.gethHangarData();
                        }
                    }).finally(() => {
                        this.hangarBasePopConfig.loading = false;
                    });
                }
            });
        }
    },
    beforeDestroy() {
        this.searchForm = {
            deptId: '',
            hangarName: '',
            droneName: '',
            time: [],
        };
        this.chooseHangarItem = {};
    }
}
</script>

<style lang="scss" scoped>
.hangar-list {
    width: 414px;
    min-width: 345px;
    border-top: 1px solid #030303;
    background-color: #1e222a;
    height: 100%;
    display: flex;
    flex-direction: column;
    .hangar-list_top {
        border-bottom: 1px solid #030303;
        padding: 10px;
        .search-top {
            display: flex;
            margin-bottom: 8px;
            .hangar_input {
                margin-left: 10px;
            }
        }
        ::v-deep .el-input__inner {
            background-color: #030303;
            border-color: #535353;
            border-radius: 2px;
            color: #FFFFFF;
            font-size: 13px;
        }
        ::v-deep .el-date-editor {
            width: 100%;
            margin-top: 8px;
            .el-range-separator {
                color: #FFFFFF;
            }
            .el-range-input {
                width: 45%;
                background-color: transparent;
                color: #FFFFFF;
                font-size: 13px;
            }
        }
    }
    .hangar-list_center {
        flex: 1;
        color: #fff;
        overflow: auto;
        .no-data {
            text-align: center;
            margin-top: 10px;
            color: #76787D;
            font-size: 14px;
            .el-icon-folder-delete {
                font-size: 25px;
            }
        }
        .list_item {
            padding: 15px 10px;
            border-bottom: 1px solid #030303;
            cursor: pointer;
            &.active {
                background-color: #3573FF33;
            }
            .list_item-top {
                display: flex;
                .list_item-title {
                    flex: 1;
                    font-size: 15px;
                    font-weight: 700;
                    font-style: normal;
                    font-family: MicrosoftYaHeiUI;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    word-break: break-all;
                }
                .list_item-icon {
                    i {
                        margin-left: 6px;
                        font-size: 16px;
                        cursor: pointer;
                    }
                    .el-icon-view {
                        &.active {
                            color: #409EFF;
                        }
                    }
                }
            }
            .list_item-msg {
                font-size: 12px;
                font-family: MicrosoftYaHeiUI;
                color: rgba(153, 153, 153, 0.8);
                margin-top: 7px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                word-break: break-all;
            }
        }
    }
    .hangar-list_bottom {
        display: flex;
        flex-direction: column;
        padding: 15px 40px;
        color: #fff;
        border-top: 1px solid #030303;
        .bottom_btn {
            text-align: center;
            padding: 11px 0;
            background: #265edd;
            border-radius: 3px;
            font-size: 14px;
            font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
            font-weight: bold;
            border: none;
            cursor: pointer;
            ::v-deep .el-icon-plus {
                font-weight: 600;
            }
        }
    }
    .hangarBaseDialog {
        ::v-deep .el-dialog {
            background-color: rgba(30, 34, 42, 1);
            .el-dialog__header {
                .el-dialog__title {
                    font-size: 16px;
                    color: #FFFFFF;
                }
                .el-dialog__headerbtn .el-dialog__close {
                    color: #ffffff;
                }
            }
            .el-dialog__body {
                padding: 15px 20px;
                .content {
                    .form-item {
                        display: flex;
                        .el-form-item {
                            flex: 1;
                            .el-form-item__label {
                                color: #ffffff;
                            }
                            .el-input__inner {
                                font-size: 14px;
                                color: #ffffff;
                                background-color: transparent;
                                &::placeholder {
                                    color:  rgba(153, 153, 153, 0.79);
                                }
                            }
                            .el-textarea__inner {
                                font-size: 14px;
                                color: #ffffff;
                                background-color: transparent;
                                resize: none;
                                &::placeholder {
                                    color:  rgba(153, 153, 153, 0.79);
                                }
                            }
                            .upload_cont {
                                display: flex;
                                cursor: pointer;
                                .img_cont {
                                    width: 96px;
                                    height: 96px;
                                    border: 1px solid #DCDFE6;
                                    border-radius: 4px;
                                    position: relative;
                                    .delete-icon {
                                        -webkit-box-sizing: border-box;
                                        box-sizing: border-box;
                                        border-top: 16px #CE1917 solid;
                                        border-right: 16px #CE1917 solid;
                                        border-left: 16px #0000 solid;
                                        border-bottom: 16px #0000 solid;
                                        border-radius: 4px;
                                        width: 16px;
                                        height: 16px;
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        cursor: pointer;
                                    }
                                    .el-icon-delete {
                                        position: absolute;
                                        top: 4px;
                                        right: 2px;
                                        color: #fff;
                                        font-size: 13px;
                                        background: transparent;
                                    }
                                }
                                .upload {
                                    width: 96px;
                                    height: 96px;
                                    border: 1px dashed #DCDFE6;
                                    border-radius: 4px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    .el-icon-plus {
                                        color: #fff;
                                        font-size: 20px;
                                    }
                                }
                            }
                            .remark {
                                font-size: 12px;
                                line-height: 18px;
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>