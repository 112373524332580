<template>
    <!-- 机库管理 -->
    <div class="container normal mission-center-box">
        <HangarList />
        <CusiumMap ref="hangarCusiumMap" class="mission-center-lastItem" />
    </div>
</template>

<script>
import HangarList from './operationBar/hangarList';
import CusiumMap from "@/components/cesium/index";

export default {
    name: 'hangar-management',
    components: {CusiumMap, HangarList}
}
</script>

<style lang="scss" scoped>
.mission-center-box {
    width: 100%;
    height: 100%;
    display: flex;
    .mission-center-lastItem{
        flex-grow: 1;
        overflow: hidden;
    }
}
</style>